@use '../base/' as *;

.projects {
    background-color: $secondary-color;
    padding: 5rem 0;
    color: $page-color;

    h2 {
        text-align: center;
        margin-bottom: 5rem;
        font-size: 2.3em;
    }

    h3 {
        font-size: 1.9em;
    }
    
    .flex-container {
        max-width: 60rem;
        margin: 0 auto;
        gap: 2rem;

        display: flex;
        flex-direction: column;

        article {
            display: grid;
            grid-template-columns: 1fr 1fr;
            
            padding: 1.5em;
            gap: 2em;

            @include resize(max-width, '600px') {
                grid-template-columns: 1fr;

                &:nth-child(even) {
                    background-color: $accent-2;

                    img {
                        grid-row: 2/3;
                    }

                    button {
                        background-color: $primary-color;
                    }
                }

            }

            &:nth-child(odd){
                img {
                    border-radius: 10px 30%;
                }
                text-align: left;
            }
            &:nth-child(even){
                img {
                    border-radius: 30% 10px;
                }
            }

            div {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }

            img {
                max-width: 100%;
                aspect-ratio: 1/1;
                object-fit: cover;
                display: inline;

            }

            p {
                font-size: 1.5em;
            }
        }

        button {
            width: fit-content;
            font-size: 1.2em;
            padding: .5em;
            border: none;
            border-radius: 5px;
            background-color: $accent-1;
            
            a {
                color: $page-color;
                text-decoration: none;
            }
        }
    }


}