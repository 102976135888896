@use '../base' as *;

.main-navbar {
    padding: 1em 2em;
    background-color: $primary-color;
    max-height: 6rem;
    z-index: 1;

    color: $page-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    min-width: calc(100vw - 4em);

    a {
        color: $page-color;
        text-decoration: none;
    }

    .menu-icon {
        font-size: 2.5em;
        cursor: pointer;

        @include resize(max-width, '768px') {
            display: none;
        }
    }

    img {
        max-height: inherit;
    }

    div {
        display: flex;
        align-items: center;
        gap: 2em;
    }

    h1 {
        display: inline;
        font-size: 1.7em;
    }

    .personal-pic {
        border-radius: 50%;
        max-height: 5rem;
    }
}
