:root {
  font-family: quicksand, sans-serif;
  font-weight: 400;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #edf2f4;
}

.content {
  overflow-x: hidden;
  transition: max-width 250ms ease-in-out, margin-left 250ms ease-in-out;
}

.main-aside.open ~ .content {
  max-width: calc(100vw - 15rem);
  margin-left: 15rem;
}
@media only screen and (max-width: 900px) {
  .main-aside.open ~ .content {
    max-width: 100vw;
  }
}

.main-aside.closed ~ .content {
  max-width: 100vw;
  margin-left: auto;
}

.main-navbar {
  padding: 1em 2em;
  background-color: #2b2d42;
  max-height: 6rem;
  z-index: 1;
  color: #edf2f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  min-width: calc(100vw - 4em);
}
.main-navbar a {
  color: #edf2f4;
  text-decoration: none;
}
.main-navbar .menu-icon {
  font-size: 2.5em;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .main-navbar .menu-icon {
    display: none;
  }
}
.main-navbar img {
  max-height: inherit;
}
.main-navbar div {
  display: flex;
  align-items: center;
  gap: 2em;
}
.main-navbar h1 {
  display: inline;
  font-size: 1.7em;
}
.main-navbar .personal-pic {
  border-radius: 50%;
  max-height: 5rem;
}

.growth-section {
  max-width: inherit;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: #2b2d42;
  margin: 0 auto;
  color: #edf2f4;
}
.growth-section .grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  align-items: center;
  max-width: 70rem;
  margin: 0 auto;
}
@media only screen and (max-width: 700px) {
  .growth-section .grid-container {
    padding-top: 5rem;
    grid-template-columns: 1fr;
  }
}
.growth-section h1 {
  font-size: 2.2em;
}
.growth-section div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}
.growth-section div img {
  max-width: 25rem;
  border-radius: 50%;
}
@media only screen and (max-width: 400px) {
  .growth-section div img {
    max-width: 15rem;
  }
}
.growth-section article {
  padding: 2em 1em;
  line-height: 2.3em;
  border-radius: 10px;
  height: fit-content;
}
.growth-section article h1 {
  margin-bottom: 1em;
}
.growth-section article p, .growth-section article li {
  font-size: 1.5em;
}
.growth-section article button {
  padding: 0.5em;
  font-size: 1.3em;
  border: none;
  border-radius: 5px;
  margin-top: 2rem;
  color: #edf2f4;
  background-color: #ef233c;
  cursor: pointer;
}
.growth-section article button a {
  color: #edf2f4;
  text-decoration: none;
}
.growth-section article ul {
  padding: 0.5em 4em;
}
.growth-section > a {
  color: #edf2f4;
  position: absolute;
  bottom: 0;
  width: 100vw;
  text-align: center;
  font-size: 3em;
  opacity: 0.7;
}
.growth-section .arrow-down-icon {
  font-size: 3em;
  cursor: pointer;
  animation: bounce 1s infinite;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.main-aside {
  position: fixed;
  padding-top: 5rem;
  height: 100vh;
  width: 0;
  background-color: #edf2f4;
  transition: width 250ms ease-in-out;
}
.main-aside div {
  opacity: 0;
  pointer-events: none;
  color: #2b2d42;
  transition: opacity 150ms ease-in-out;
  font-size: 1.5em;
  list-style: none;
  height: inherit;
  display: flex;
  flex-direction: column;
}
.main-aside.open {
  width: 15rem;
}
.main-aside.open div {
  opacity: 1;
  pointer-events: all;
  transition: opacity 250ms 250ms ease-in-out;
}
.main-aside a {
  padding: 1em;
  width: inherit;
  color: #2b2d42;
  text-decoration: none;
  transition: background-color 250ms ease-in-out;
}
.main-aside a:hover {
  background-color: #ef233c;
  cursor: pointer;
}

.main-skills {
  max-width: inherit;
  padding: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.main-skills article {
  text-align: center;
}
.main-skills article i {
  font-size: 1.5em;
  color: #d90429;
  margin-bottom: 0.4em;
}
.main-skills article h3 {
  font-size: 1.4em;
  margin-bottom: 0.4em;
}
.main-skills article p {
  max-width: 35ch;
}

.projects {
  background-color: #8d99ae;
  padding: 5rem 0;
  color: #edf2f4;
}
.projects h2 {
  text-align: center;
  margin-bottom: 5rem;
  font-size: 2.3em;
}
.projects h3 {
  font-size: 1.9em;
}
.projects .flex-container {
  max-width: 60rem;
  margin: 0 auto;
  gap: 2rem;
  display: flex;
  flex-direction: column;
}
.projects .flex-container article {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1.5em;
  gap: 2em;
}
@media only screen and (max-width: 600px) {
  .projects .flex-container article {
    grid-template-columns: 1fr;
  }
  .projects .flex-container article:nth-child(even) {
    background-color: #d90429;
  }
  .projects .flex-container article:nth-child(even) img {
    grid-row: 2/3;
  }
  .projects .flex-container article:nth-child(even) button {
    background-color: #2b2d42;
  }
}
.projects .flex-container article:nth-child(odd) {
  text-align: left;
}
.projects .flex-container article:nth-child(odd) img {
  border-radius: 10px 30%;
}
.projects .flex-container article:nth-child(even) img {
  border-radius: 30% 10px;
}
.projects .flex-container article div {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.projects .flex-container article img {
  max-width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  display: inline;
}
.projects .flex-container article p {
  font-size: 1.5em;
}
.projects .flex-container button {
  width: fit-content;
  font-size: 1.2em;
  padding: 0.5em;
  border: none;
  border-radius: 5px;
  background-color: #ef233c;
}
.projects .flex-container button a {
  color: #edf2f4;
  text-decoration: none;
}

.main-footer {
  padding: 3rem;
}
.main-footer div {
  max-width: 40rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  font-size: 2em;
}
.main-footer div .icon {
  color: #2b2d42;
  transition: color 250ms ease-in-out;
  font-size: 1.5em;
}
.main-footer div .icon:hover {
  color: #d90429;
}

.contact-page {
  padding-top: 5rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  max-width: 100vw;
  gap: 3rem;
}
@media only screen and (max-width: 850px) {
  .contact-page {
    grid-template-columns: 1fr;
  }
  .contact-page img {
    display: none;
  }
  .contact-page form {
    margin: 0 auto;
  }
}
.contact-page img {
  max-width: 100%;
  border-radius: 0 40% 40% 0;
  height: 50rem;
  object-fit: cover;
}
.contact-page form {
  max-width: 25rem;
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 2em;
  background-color: #2b2d42;
  border-radius: 10px;
}
@media only screen and (max-width: 850px) {
  .contact-page form {
    margin: 0 auto;
  }
}
.contact-page form div {
  width: inherit;
  display: flex;
  justify-content: center;
  gap: 2em;
}
.contact-page form input, .contact-page form label {
  max-width: inherit;
  text-align: left;
}
.contact-page form label {
  color: #edf2f4;
  font-size: 1.5em;
}
.contact-page form input, .contact-page form textarea {
  padding: 0.5em;
  border: none;
  border-radius: 10px;
  font-size: 1.5em;
}
.contact-page form input:focus, .contact-page form textarea:focus {
  outline: 2px solid #ef233c;
}
.contact-page form textarea {
  resize: none;
  min-height: 10ch;
}
.contact-page h1 {
  color: #ef233c;
  text-align: center;
  font-size: 2.4em;
}
.contact-page button {
  padding: 0.5em;
  border: none;
  border-radius: 5px;
  background-color: #ef233c;
  color: #edf2f4;
  font-size: 1.4em;
  cursor: pointer;
}

body {
  overflow-x: hidden;
}

