$page-color: #edf2f4;
$primary-color: #2b2d42;
$secondary-color: #8d99ae;
$accent-1: #ef233c;
$accent-2: #d90429;

@mixin font {
    font-family: quicksand,sans-serif;
    font-weight: 400;
    font-style: normal;
}

@mixin resize($minmax, $amount) {
    @media only screen and ($minmax: $amount) {
        @content;
    }
}

:root {
    @include font;
}

* {
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: $page-color;

}

.content {
    overflow-x: hidden;
    
    transition: max-width 250ms ease-in-out, margin-left 250ms ease-in-out;
}

.main-aside.open ~ .content {
    max-width: calc(100vw - 15rem);
    margin-left: 15rem;

    @include resize(max-width, '900px') {
        max-width: 100vw;
    }
}
.main-aside.closed ~ .content {
    max-width: 100vw;
    margin-left: auto;
}