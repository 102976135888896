@use '../base/' as *;

.contact-page {
    padding-top: 5rem;

    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 100vw;
    gap: 3rem;

    @include resize(max-width, '850px') {
        grid-template-columns: 1fr;

        img {
            display: none;
        }

        form {
            margin: 0 auto;
        }
    }

    img {
        max-width: 100%;
        border-radius: 0 40% 40% 0;
        height: 50rem;
        object-fit: cover;
    }
    
    form {
        max-width: 25rem;

        @media only screen and (max-width: 850px) {
            margin: 0 auto;
        }
        
        margin: 3rem 0;
        display: flex;
        flex-direction: column;
        gap: 2em;
        padding: 2em;
        background-color: $primary-color;
        border-radius: 10px;

        div {
            width: inherit;
            display: flex;
            justify-content: center;
            gap: 2em;
        }

        input, label {
            max-width: inherit;
            // margin: 0 auto;
            text-align: left;
        }

        label {
            color: $page-color;
            font-size: 1.5em;
        }

        input, textarea {
            padding: .5em;
            border: none;
            border-radius: 10px;
            font-size: 1.5em;

            &:focus {
                outline: 2px solid $accent-1;
            }
        }
        
        textarea {
            resize: none;
            min-height: 10ch;
        }
    }
    
    h1 {
        color: $accent-1;
        text-align: center;
        font-size: 2.4em;
    }

    button {
        padding: .5em;
        border: none;
        border-radius: 5px;
        background-color: $accent-1;
        color: $page-color;
        font-size: 1.4em;
        cursor: pointer;
    }
}
