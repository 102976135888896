@use '../base' as *;

.main-footer {
    padding: 3rem;

    div {
        max-width: 40rem;
        margin: 0 auto;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        font-size: 2em;

        .icon {
            color: $primary-color;
            transition: color 250ms ease-in-out;
            font-size: 1.5em;

            &:hover {
                color: $accent-2;
            }
        }
    }
}
