@use '../base' as *;

@keyframes appear {
    from {opacity: 0;}
    to {opacity: 1;}
}

@keyframes disappear {
    from {opacity: 1;}
    to {opacity: 0;}
}

.main-aside {
    position: fixed;
    padding-top: 5rem;
    height: 100vh;

    width: 0;
    background-color: $page-color;

    transition: width 250ms ease-in-out;

    div {
        opacity: 0;
        pointer-events: none;
        color: $primary-color;
        transition: opacity 150ms ease-in-out;
        font-size: 1.5em;
        list-style: none;

        height: inherit;

        display: flex;
        flex-direction: column;
        // gap: 2em;
    }

    &.open {
        width: 15rem;

        div {
            opacity: 1;
            pointer-events: all;
            transition: opacity 250ms 250ms ease-in-out;
        }

    }

    a {
        padding: 1em;
        width: inherit;
        color: $primary-color;
        text-decoration: none;

        transition: background-color 250ms ease-in-out;

        &:hover {
            background-color: $accent-1;
            cursor: pointer;
        }
    }


}
