@use '../base/' as *;

.main-skills {
    max-width: inherit;
    padding: 4rem;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;

    article {
        text-align: center;

        i {
            font-size: 1.5em;
            color: $accent-2;
            margin-bottom: .4em;
        }
        
        h3 {
            font-size: 1.4em;
            margin-bottom: .4em;
        }
        
        p {
            max-width: 35ch;
        }
    }
}