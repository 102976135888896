@use '../base' as *;

.growth-section {

    max-width: inherit;
    min-height: 100vh;

    display: flex;
    align-items: center;


    background-color: $primary-color;
    // border-radius: 10px;

    margin: 0 auto;
    // padding: 10em 2em;

    color: $page-color;

    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        align-items: center;

        max-width: 70rem;
        margin: 0 auto;
        // padding-top: 10rem;

        @include resize(max-width, '700px'){
            padding-top: 5rem;
            grid-template-columns: 1fr;
            // grid-template-rows: 1fr 1fr;
        }
    }

    h1 {
        font-size: 2.2em;
    }

    div {

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5rem;

        img {
            max-width: 25rem;
            border-radius: 50%;

            @include resize(max-width, '400px') {
                max-width: 15rem;
            }
        }
    }


    article {
        // background-color: $secondary-color;
        padding: 2em 1em;
        line-height: 2.3em;
        border-radius: 10px;
        height: fit-content;

        h1 {
            margin-bottom: 1em;
            // text-align: center;
        }

        p, li {
            font-size: 1.5em;
        }

        button {
            padding: .5em;
            font-size: 1.3em;
            border: none;
            border-radius: 5px;

            margin-top: 2rem;
            color: $page-color;
            background-color: $accent-1;
            cursor: pointer;

            a {
                color: $page-color;
                text-decoration: none;
            }
        }

        ul {
            padding: .5em 4em;
        }
    }

    > a {
        color: $page-color;
        position: absolute;
        bottom: 0;
        width: 100vw;
        text-align: center;
        font-size: 3em;
        opacity: .7;
    }

    .arrow-down-icon {
        font-size: 3em;
        cursor: pointer;
        animation: bounce 1s infinite;

        @keyframes bounce {
            0% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(1rem);
            }
            100% {
                transform: translateY(0);
            }
        }
    }

}

